import * as React from 'react';
import { Card } from 'primereact/card';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputSwitch } from 'primereact/inputswitch';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboard, faPlusCircle } from '@fortawesome/pro-light-svg-icons';
import CreateEditFlag from '../flags/CreateEditFlag';
import { useAxios } from '../../utils/hooks.ts';
import { Button } from 'primereact/button';
import { confirmPopup } from 'primereact/confirmpopup';
import FlagDetails from '../flags/FlagDetails';
import EnvironmentOptions from './EnvironmentOptions';
import { FilterMatchMode } from 'primereact/api';
import { useNavigate } from 'react-router-dom';

export default function EnvironmentSummary({ role, environment, flipFetchFlag, toast }) {
    const [ createVisible, setCreateVisible ] = React.useState(false);
    const [ detailsVisible, setDetailsVisible ] = React.useState(false);
    const [ selectedFlag, setSelectedFlag ] = React.useState(null);
    const [ filters ] = React.useState({
        'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
        'shortId': {value: null, matchMode: FilterMatchMode.STARTS_WITH},
        'name': {value: null, matchMode: FilterMatchMode.STARTS_WITH},
        'percentage': {value: null, matchMode: FilterMatchMode.EQUALS}
    })
    const navigate = useNavigate();
    const axiosInstance = useAxios();

    const toggleVisible = () => {
        setCreateVisible(!createVisible);
    }

    const toggleDetails = () => {
        setDetailsVisible(!detailsVisible);
    }

    const displayToast = (msg) => {
        toast.current.show(msg);
        flipFetchFlag();
    }

    const toggleFlag = (flag) => {
        const data = {
            id: flag.id,
            flagId: flag.flagId,
            environmentId: environment.environmentId,
            enabled: !flag.enabled
        }
        axiosInstance.current.post("/flags/enable", data).then(() => {
            flag.enabled = data.enabled;
            flipFetchFlag();
        })
        .catch((err) => {
            console.log(err);
        })
    }

    const editFlag = (flag) => {
        setSelectedFlag(flag);
        setCreateVisible(true);
    }

    const reject = () => {
    };

	const confirmDeleteFlag = (flag, e) => {
		confirmPopup({
			target: e.currentTarget,
			message: "Are you sure you want to delete this flag?",
			icon: 'pi pi-info-circle',
			acceptClassName: 'p-button-danger',
			accept: () => deleteFlag(flag),
			reject
		})
	}

    const deleteFlag = (flag) => {
		axiosInstance.current.delete("/flags/" + flag.id).then((response) => {
            flipFetchFlag();
		})
		.catch((err) => {
            console.log(err);
			toast.current.show({
				severity: 'error',
				summary: 'Unable to delete flag!',
				detail: err.response ? err.response.data : ''
			});
		})
	}

    const copyToClipboard = () => {
        navigator.clipboard.write(environment.environmentId);
        toast.current.show({ severity: 'info', detail: 'Copied!'});
    }

    const isDisabled = () => {
        if (role === 'VIEWER') {
            return true;
        }
        if (environment.protect && (role === 'USER' || role === 'VIEWER')) {
            return true;
        }
        return false;
    }

    const enabledBody = (rowData) => {
        return <InputSwitch checked={rowData.enabled} onChange={() => toggleFlag(rowData)} disabled={isDisabled()} />
    }
    
    const linkBody = (rowData) => {
        return <Button className="p-button-outlined" icon="pi pi-link" onClick={() => navigate("/flags/" + rowData.flagId)} disabled={isDisabled()} />
    }

    const viewBody = (rowData) => {
        return <Button label="View Details" className="p-button-text" icon="pi pi-angle-right" onClick={() => {setSelectedFlag(rowData); setDetailsVisible(true);}} disabled={isDisabled()} />
    }

    const editBody = (rowData) => {
        return <Button className="p-button-outlined" icon="pi pi-pencil" onClick={() => editFlag(rowData)} disabled={isDisabled()} />
    }

    const deleteBody = (rowData) => {
        return <Button className="p-button-outlined p-button-danger" icon="pi pi-trash" onClick={(e) => confirmDeleteFlag(rowData, e)} disabled={isDisabled()}/>
    }

    const header = (
        <EnvironmentOptions environment={environment} displayToast={displayToast} flipFetchFlag={flipFetchFlag} toast={toast} role={role} />
    )

    return (
        <div className="mt10">
            <CreateEditFlag flag={selectedFlag} visible={createVisible} hide={toggleVisible} displayToast={displayToast} environmentId={environment.environmentId} />
            <FlagDetails visible={detailsVisible} hide={toggleDetails} flag={selectedFlag} flipFetchFlag={flipFetchFlag} apiKey={environment.apiKey} toggleFlag={toggleFlag} />
            <Card title={environment.name} header={header}>
                {environment.protect && <div className="mt10 danger"><b>Protected</b></div>}
                <div className="mt10">
                    {environment.environmentId} <FontAwesomeIcon icon={faClipboard} style={{ marginLeft: 10, cursor: 'pointer' }} onClick={copyToClipboard} />
                </div>
                <div className="tertiary-title mt10">
                    Flags
                </div>
                <div className="tertiary-title mt10 pointer" onClick={() => {setSelectedFlag(null); setCreateVisible(true);}}>
                    {environment.protect ?
                        <>{(role === 'ADMIN' || role === 'OWNER') && <><FontAwesomeIcon icon={faPlusCircle}/><span className="ml10">Add Flag</span></>}</>
                    :
                        <><FontAwesomeIcon icon={faPlusCircle}/><span className="ml10">Add Flag</span></>
                    }
                </div>
                <DataTable
                    value={environment.flags.filter(flag => !flag.archived)}
                    rows={10}
                    paginator
                    responsiveLayout="stack"
                    size="small"
                    dataKey="flagId"
                    filters={filters}
                    filterDisplay='row'
                    globalFilterFields={['name', 'percentage']}
                >
                    <Column field="shortId" header="Id" filter filterPlaceholder="Filter by ID" sortable></Column>
                    <Column body={linkBody}></Column>
                    <Column field="name" header="Name" filter filterPlaceholder="Filter by Name" sortable></Column>
                    <Column field="percentage" header="Target (%)" filter filterPlaceholder="Filter by Target" sortable></Column>
                    <Column field="audience" header="Current Audience (%)"></Column>
                    <Column body={enabledBody} header="Enabled"></Column>
                    <Column body={viewBody}></Column>
                    <Column body={editBody}></Column>
                    <Column body={deleteBody}></Column>
                </DataTable>
            </Card>
        </div>
    )
}